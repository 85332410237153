import * as React from 'react';
import * as Styled from './homeBottomStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import GiftCardPopUp from '../GiftCardPopUp/GiftCardPopUp';
import isExternalUrl, {
  isExternalUrlhref,
  addTrailingSlash,
} from '../../utils';

const HomeBottom = (props) => {
  const [modalShowPropane, setModalShowPropane] = React.useState(false);
  const handleModalShowPropane = (isShow) => {
    isShow
      ? setModalShowPropane(isShow)
      : setModalShowPropane(!modalShowPropane);
  };
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Styled.ParaText>{children}</Styled.ParaText>,
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
        <Styled.InnerContainer>
          { props?.sectionData?.listOfImagesWithPath?.map((item, index) => {
            return (
              item?.path !==
              '/modal/HomePageGiftCard' ? (
                <Styled.AnchorStyle
                  key={index}
                  href={
                    isExternalUrlhref(item?.path)
                      ? item?.path
                      : addTrailingSlash(
                          process.env.GATSBY_DOMAIN_URL + item?.path
                        )
                  }
                  target={isExternalUrl(item?.path) ? '_blank' : '_self'}
                  >
                  <Styled.Header2 type={item.type}>
                    {item?.title}
                  </Styled.Header2>
                  <Styled.ImageStyle
                    className="home-bottom-images"
                    src={item?.image?.file?.url}
                    alt="home-bottom-images"
                    $title={item?.title}
                  />
                  {item?.richText &&
                    documentToReactComponents(
                      JSON.parse(item?.richText?.raw),
                      optionsMainStyle
                    )}
                </Styled.AnchorStyle>
              ) : (
                <Styled.AnchorStyle
                    onClick={handleModalShowPropane}
                  >
                  <Styled.Header2 type={item.type}>
                    {item?.title}
                  </Styled.Header2>
                  <Styled.ImageStyle
                    className="home-bottom-images"
                    src={item?.image?.file?.url}
                    alt="home-bottom-images"
                  />
                  {item?.richText &&
                    documentToReactComponents(
                      JSON.parse(item?.richText?.raw),
                      optionsMainStyle
                    )}
                </Styled.AnchorStyle>
              )
            );
          })}
        </Styled.InnerContainer>
        <GiftCardPopUp
          modalShow={modalShowPropane}
          handleModalShow={handleModalShowPropane}
        />
      <div style={{ clear: 'both' }} />
    </>
  );
};

export default HomeBottom;
