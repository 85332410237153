import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import HoverImage from '../../images/homeBottom-item-bg.gif';

export const MainContainer = styled(Container)`
  background: #fff !important;
  position: relative;
  @media (max-width: 991px) {
    margin-top: -84px;
    z-index: 2;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
    margin: 0;
  }
`;

export const InnerContainer = styled.div`
  width: 1200px !important;
  margin: auto;
  margin-top: -71px;
  @media (max-width: 1199px) {
    width: 1000px !important;
    margin-top: -76px;
  }
  @media (max-width: 991px) {
    width: 728px !important;
    margin-top: -84px;
  }
  @media (max-width: 767px) {
    width: 100% !important;
    margin: 0px !important;
    margin-top: 0px !important;
  }
`;

export const AnchorStyle = styled.a`
  display: block;
  position: relative;
  float: left;
  width: 25.33%;
  padding: 40px 25px;
  margin: 40px 40px 60px 54px;
  border: 5px solid #ffe400;
  font-family: 'Tungsten-Medium', arial, sans-serif;
  font-size: 30px;
  line-height: 32px;
  color: #888;
  text-align: center;
  height: 500px;
  text-decoration: none;
  @media (max-width: 1199px) {
    margin: 30px 40px 60px 40px;
    padding: 40px 25px;
  }
  @media (max-width: 999px) {
    margin: 30px 30px 40px 20px;
  }
  @media (max-width: 767px) {
    margin-left: 10px;
    float: none;
    padding: 25px 15px;
    width: 95%;
    height: auto;
    text-align: center;

  }
  :hover {
    text-decoration: none;
    color: #0c4724;
    background: url(${HoverImage})
      repeat-x bottom left;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
  }
  @media screen and (max-width: 980px)
 {
}
@media screen and (max-width: 1285px)
 {
    font-size: 20px;
    line-height: 22px;
}
`;

export const Header2 = styled.h2`
    font-family: 'Tungsten-Medium', arial, sans-serif;
    font-size: 45px;
    line-height: 36px;
    color: #198e48;
    padding-top: 40px;
    margin: 0 0 25px 0;
  @media (max-width: 767px) {
    font-size: 25px !important;
    line-height: 26px !important;
    margin-bottom: 20px;
  }
  
@media screen and (max-width: 1285px)
 {
    font-size: 25px !important;
    line-height: 26px !important;
}
@media (max-width: 767px) {
  text-align: left;
  max-width:60%;
  padding-top: 20px;
}
`;

export const ImageStyle = styled.img`
  width: auto;
  height: auto;
  float: center;
  border: 0;
  vertical-align: top;
  margin-bottom: auto;
  margin: 0 auto auto 0 !important;
  @media (max-width: 1199px) {
    margin-left: 0 !important;
  }
  @media (max-width: 991px) {
  float: none !important;
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: ${(props) =>props.$title.includes("GIFT CARD BALANCE" || "GIFT CARDs") ? '55px' : '30px'} !important;
  margin-left: 0 !important;
  }
  @media (max-width: 767px) {
    position: absolute;
    float: right !important;
    right: 3vw;
    top: 6vh;
    margin: 0 0 0 25px !important;
  }

  `;

export const ParaText = styled.div`
  font-family: 'Tungsten-Medium', arial, sans-serif;
  font-size: 25px;
  line-height: 32px;
  margin-top: 10px;
  @media (max-width: 1199px) {
    font-size: 20px;
    line-height: 22px;
  }
  @media (max-width: 991px) {
    font-size: 20px;
    line-height: 22px;
  }  
  @media (max-width: 767px) {
    max-width: 60%;
    font-size: 20px;
    line-height: 22px;
    text-align: left;
  }
`;
