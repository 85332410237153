import React from 'react';
import * as Styled from './GiftCardPopUpStyles';
import constants from '../../utils/utils';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { useContentfulImages } from '../../hooks/useContentfulImages';
import isExternalUrl, {
  isExternalUrlhref,
  addTrailingSlash,
} from '../../utils';

const PropanePopUp = ({ modalShow, handleModalShow }) => {
  const propanePopUpQuery = useStaticQuery(graphql`
    query propaneQuery {
      allContentfulFasGasPlus(
        filter: {
          node_locale: { eq: "en-US" }
          identifier: { type: { eq: "sponsorship" } }
        }
      ) {
        edges {
          node {
            id
            title
            identifier {
              type
            }
            slug
            sections {
              ... on ContentfulImageWithPath {
                id
                title
                type
                richText {
                  raw
                }
                image {
                  file {
                    url
                  }
                }
                path
              }
            }
          }
        }
      }
    }
  `);
  const propanePopUpData =
    propanePopUpQuery.allContentfulFasGasPlus.edges[0].node.sections;

  const checkYourBalance = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.GIFTCARDS_POP_UP.CHECK_YOUR_BALANCE;
  })[0];

  const purchaseagiftcard = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.GIFTCARDS_POP_UP.PURCHASE_A_GIFT_CARD;
  })[0];

  const corporateorders = propanePopUpData.filter((section) => {
    return section.type === constants.COMMON.GIFTCARDS_POP_UP.CORPORATE_OREDRS;
  })[0];

  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  const optionsStyle = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <Styled.HeaderH1>{children}</Styled.HeaderH1>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText2>{children}</Styled.ParaText2>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <Styled.LinkStyle
            href={
              isExternalUrlhref(node?.data?.uri)
                ? node?.data?.uri
                : process.env.GATSBY_DOMAIN_URL + node?.data?.uri
            }
            target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
          >
            {children}
          </Styled.LinkStyle>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return (
    <>
      <Styled.MainModal
        show={modalShow}
        onHide={() => handleModalShow(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Styled.CloseImage
          onClick={() => {
            handleModalShow(false);
          }}
        />
        <Styled.CustomerSelect>
          <Styled.RowWrapper>
            <Styled.Column xs={12} md={4}>
              <Styled.LinkStyle
                href={
                  isExternalUrlhref(checkYourBalance?.path)
                    ? checkYourBalance?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + checkYourBalance?.path
                      )
                }
                target={
                  isExternalUrl(checkYourBalance?.path) ? '_blank' : '_self'
                }
              >
                {documentToReactComponents(
                  JSON.parse(checkYourBalance?.richText?.raw),
                  optionsStyle
                )}
              </Styled.LinkStyle>
            </Styled.Column>
            <Styled.Column xs={12} md={4}>
              <Styled.LinkStyle
                href={
                  isExternalUrlhref(purchaseagiftcard?.path)
                    ? purchaseagiftcard?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + purchaseagiftcard?.path
                      )
                }
                target={
                  isExternalUrl(purchaseagiftcard?.path) ? '_blank' : '_self'
                }
              >
                {documentToReactComponents(
                  JSON.parse(purchaseagiftcard?.richText?.raw),
                  optionsStyle
                )}
              </Styled.LinkStyle>
            </Styled.Column>
            <Styled.Column xs={12} md={4}>
              <Styled.LinkStyle
                href={
                  isExternalUrlhref(corporateorders?.path)
                    ? corporateorders?.path
                    : addTrailingSlash(
                        process.env.GATSBY_DOMAIN_URL + corporateorders?.path
                      )
                }
                target={
                  isExternalUrl(corporateorders?.path) ? '_blank' : '_self'
                }
              >
                {documentToReactComponents(
                  JSON.parse(corporateorders?.richText?.raw),
                  optionsStyle
                )}
              </Styled.LinkStyle>
            </Styled.Column>
          </Styled.RowWrapper>
        </Styled.CustomerSelect>
      </Styled.MainModal>
    </>
  );
};
export default PropanePopUp;
